import React, {Component} from 'react';
import PropTypes from 'prop-types'
import AD_Showcase from "./AD_Showcase";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import IosImage from "../../src-assets/app_store.svg"
import AndroidImage from "../../src-assets/play_store.svg"
import loginImg from "../../src-assets/TL-ResponsiveMockup-nomerge.png"
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";


// TODO: [P3] Last arrow at the bottom of page does not show up on screen sizes <= iPhone 5 SE
class AD_Home extends Component {

    render() {

        // Determine whether viewport is mobile sized (iPad or less)
        const isMobile = window.matchMedia("(max-width: 760px)").matches;

        // Change CSS depending on mobile status above
        let mainPageContainerCSS = isMobile ? mainPageStyleMobile : mainPageStyle;
        let callActionCSS = isMobile ? callActionStyleMobile : callActionStyle;
        let callActionTitleCSS = isMobile ? callActionTitleStyleMobile : callActionTitleStyle;
        let callActionButtonCSS = isMobile ? callActionButtonStyleMobile : callActionButtonStyle;
        let arrowCSS = isMobile ? arrowDownStyleMobile : arrowDownStyle;

        return (
            <div style={mainPageContainerCSS} ref={this.props.adPage.reference}>
                <div style={showcaseContainer}>
                    <img src={loginImg}
                         style={imageCSS}
                         alt="iPad App Preview"/>
                    <div style={downloadImagesContainer}>
                    </div>
                </div>

                <div style={callActionCSS}>
                    <h2 style={callActionTitleCSS}>Easy Class Management</h2>
                    <p style={{textAlign: "center", fontSize: "20px"}}>Our expanding suite of tools were created by instructors, for instructors to make your job easier.
                        Manage your classes, auto-generate forms, and keep track of all of your data with our easy to use service.
                    </p>
                    <Link to="/registration" style={{ textDecoration: 'none'}}>
                        <MuiThemeProvider theme={theme}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                style={callActionButtonCSS}
                            >
                                Register
                            </Button>
                        </MuiThemeProvider>
                    </Link>
                </div>
                <input type="image" style={arrowCSS} src="assets/arrow_down.svg" alt="" onClick={(e) => {
                    this.props.arrowDownFunction(this.props.adPage.index)
                }}/>
            </div>
        );
    }
}

const mainPageStyle = {
    display: 'grid',
    height: 'calc(100vh - 4rem)',
    gridTemplateAreas:`
    'show text'
    'arrow arrow'
    `,
    gridTemplateColumns: '2fr 1fr',
    gridTemplateRows: '14fr 1fr',
    justifyItems: 'center',
    alignItems: 'center',
    paddingTop: '22px',
    overflow: 'hidden'
};

const mainPageStyleMobile = {
    display: 'grid',
    minHeight: 'calc(100vh - 4rem)',
    gridTemplateAreas:`
    'show'
    'text'
    'arrow'
    `,
    justifyItems: 'center',
    paddingTop: '1rem',
    alignItems: 'center',
};

const showcaseContainer = {
    height:" 100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: '22px'
}

const imageCSS = {
    justifySelf: 'center',
    alignSelf: 'center',
    maxHeight: '75vh',
    maxWidth: '100%',
};

const downloadImagesContainer = {
    width: "100%",

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",

    marginTop: "1rem"

}

// Call Action
const callActionStyle = {
    gridArea: 'text',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
};

const callActionStyleMobile = {
    gridArea: 'text',
    marginTop: '0.5rem',
    padding: '1rem 2rem 0 2rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
};

const callActionTitleStyle = {
    textAlign: 'center',
    fontSize: '3rem',
    fontWeight: "bold"
};

const callActionTitleStyleMobile = {
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: "bold"
};

const callActionButtonStyle = {
    textTransform: "none",
    marginTop: '1rem',
    minHeight: '4rem',
    minWidth: '12rem',
    fontSize: '1.5rem',
};

const callActionButtonStyleMobile = {
    textTransform: "none",
    marginTop: '0.5rem',
    minHeight: '3.5rem',
    minWidth: '10rem',
    fontSize: '1.4rem',
};

const arrowDownStyle = {
    height: '3rem',
    justifyContent: 'center',
    gridColumn: '1/3'
};

const arrowDownStyleMobile = {
    height: '3rem',
    justifyContent: 'center',
    gridColumn: '1/2'
};

const theme = createMuiTheme({
    palette: {
        secondary: {
            main: '#2180CE'
        }
    },
});

// PropTypes
AD_Home.propTypes = {
    adPage: PropTypes.object.isRequired,
    arrowDownFunction: PropTypes.func.isRequired
};

export default AD_Home;